import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import OrganizationsPage from "./components/Organization";
import OrganizationDetails from "./views/organizationDetails";
import Books from "./views/books";
import Home from "./views/Home";
import Login from "./views/login";
import { AuthProvider } from "./context/AuthContext";
import Sales from "./views/sales";
import Contracts from "./views/contracts";
import Publications from "./views/publications";
import Suppliers from "./views/suppliers";
import Subscription from "./views/subscription";
import SupplierDetailsPage from "./views/suppliersDetails";
import NewLiteraryWork from "./views/newLiteraryWork";
import SalesMetrics from "./views/salesMetrics";
import Emails from "./views/emails";
import PoliciesAndPrivacy from "./views/policiesAndPrivacy";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("token") ? true : false
  );

  useEffect(() => {
    const handleAuthChange = () => {
      setIsAuthenticated(localStorage.getItem("token") ? true : false);
    };

    window.addEventListener("storage", handleAuthChange);

    return () => {
      window.removeEventListener("storage", handleAuthChange);
    };
  }, [isAuthenticated]);

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/home"
            element={isAuthenticated ? <Home /> : <Login />}
          />
          <Route
            path="/organization/:id"
            element={isAuthenticated ? <OrganizationDetails /> : <Login />}
          />
          <Route
            path="/reports"
            element={isAuthenticated ? <OrganizationsPage /> : <Login />}
          />
          <Route
            path="/books"
            element={isAuthenticated ? <Books /> : <Login />}
          />

          <Route
            path="/sales"
            element={isAuthenticated ? <Sales /> : <Login />}
          />
          <Route
            path="/contracts"
            element={isAuthenticated ? <Contracts /> : <Login />}
          />
          <Route
            path="/publications"
            element={isAuthenticated ? <Publications /> : <Login />}
          />

          <Route
            path="/suppliers"
            element={isAuthenticated ? <Suppliers /> : <Login />}
          />

          <Route
            path="/subscriptions"
            element={isAuthenticated ? <Subscription /> : <Login />}
          />

          <Route
            path="/supplier/:id"
            element={isAuthenticated ? <SupplierDetailsPage /> : <Login />}
          />
          <Route
            path="/book/:id"
            element={isAuthenticated ? <NewLiteraryWork /> : <Login />}
          />
          <Route
            path="/metrics"
            element={isAuthenticated ? <SalesMetrics /> : <Login />}
          />

          <Route
            path="/emails-templates"
            element={isAuthenticated ? <Emails /> : <Login />}
          />
          <Route
            path="/policies"
            element={isAuthenticated ? <PoliciesAndPrivacy /> : <Login />}
          />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
