import React, { useState, useEffect } from "react";
import {
  Button,
  List,
  ListItem,
  Typography,
  useTheme,
  Card,
  CardContent,
  Tabs,
  Tab,
  Stack,
  Modal,
  Box,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import NetworkManager from "../Network/NetworkManager";
import { Spinner } from "react-bootstrap";
import { toast, message, ToastContainer } from "react-toastify";

export function SuppliersAndAuthorsList() {
  const [suppliers, setSuppliers] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    telemovel: "",
  });

  const [isAuthorModalOpen, setIsSupplierstModalOpen] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();

  const networkManager = new NetworkManager();

  const handleRouter = async (id) => {
    navigate(`/supplier/${id}`);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const suppliers = await networkManager.getAllSuppliers(0, 100);
      setSuppliers(suppliers);
    } catch (error) {
      console.error("Erro ao buscar dados de fornecedores e editores: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const createSupplier = async () => {
    setIsSubmitting(true);
    try {
      const suppliers = await networkManager.createSuppliers(
        formData.nome,
        formData.email,
        formData.telemovel
      );
      setSuppliers(suppliers);

      toast.success("Fornecedor criado com sucesso!");
      setIsSubmitting(false);
      fetchData();
    } catch (error) {
      console.error("Erro ao criar fornecedor: ", error);
      toast.error("Erro ao criar fornecedor!");
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenSuppliersModal = () => setIsSupplierstModalOpen(true);
  const handleCloseSuppliersModal = () => setIsSupplierstModalOpen(false);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  return (
    <Stack marginTop={theme.spacing(8)}>
      <Tabs value={tabValue} onChange={handleChangeTab} centered>
        <Tab label="Fornecedores" />
      </Tabs>

      {tabValue === 0 && (
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            marginTop: theme.spacing(7),
          }}
        >
          <Button onClick={handleOpenSuppliersModal}>Novo Fornecedor</Button>

          {suppliers == undefined || isLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spinner animation="grow" />
            </div>
          ) : (
            suppliers.map((supplier, index) => (
              <ListItem
                key={index}
                style={{ padding: theme.spacing(1) }}
                component="div"
                disablePadding
              >
                <Card
                  variant="outlined"
                  sx={{ width: "100%" }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: theme.spacing(1),
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {supplier.nome}
                    </Typography>
                    <Typography variant="body2">{supplier.email}</Typography>
                    <Typography variant="body2">{supplier.contract}</Typography>

                    <Typography>
                      {supplier.email} - {supplier.telemovel}
                    </Typography>
                  </CardContent>

                  <Button onClick={() => handleRouter(supplier.id)}>
                    Detalhes
                  </Button>
                </Card>
              </ListItem>
            ))
          )}
        </List>
      )}

      {tabValue === 1 && (
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            marginTop: theme.spacing(7),
          }}
        >
          <Button onClick={handleOpenSuppliersModal}>Novo Fornecedor</Button>
          {authors.map((author, index) => (
            <ListItem
              key={index}
              style={{ padding: theme.spacing(1) }}
              component="div"
              disablePadding
            >
              <Card
                variant="outlined"
                sx={{ width: "100%" }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: theme.spacing(1),
                }}
              >
                <CardContent>
                  <Typography variant="h6" component="div">
                    {author.name}
                  </Typography>
                  <Typography>{author.email}</Typography>
                  <Typography>{author.telephone}</Typography>
                </CardContent>
                <Button onClick={() => handleRouter(author.name)}>
                  Detalhes
                </Button>
              </Card>
            </ListItem>
          ))}
        </List>
      )}

      <Modal
        open={isAuthorModalOpen}
        onClose={handleCloseSuppliersModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Novo Fornecedor
          </Typography>
          <TextField
            fullWidth
            label="Nome"
            margin="normal"
            onChange={(event) => {
              setFormData({ ...formData, nome: event.target.value });
            }}
          />
          <TextField
            fullWidth
            label="Email"
            margin="normal"
            onChange={(event) => {
              setFormData({ ...formData, email: event.target.value });
            }}
          />
          <TextField
            fullWidth
            label="Telefone"
            margin="normal"
            onChange={(event) => {
              setFormData({ ...formData, telemovel: event.target.value });
            }}
          />

          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() => {
              createSupplier();
              handleCloseSuppliersModal();
            }}
          >
            {isSubmitting ? <Spinner animation="grow" /> : "Criar"}
          </Button>
        </Box>
      </Modal>

      <ToastContainer />
    </Stack>
  );
}
