import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import NetworkManager from "../Network/NetworkManager";
import { Skeleton, Stack, Typography } from "@mui/material";
import { Spinner } from "react-bootstrap";
import { OrganizationsTable } from "./OrganizationTable";
import { TextField } from "@material-ui/core";

function OrganizationsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [sended, setsended] = useState([]);
  const [checked, setChecked] = useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [open, setOpen] = useState(false);
  const [htmlBodyTex, SethtmlBodyTex] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleOpen = (organization) => {
    setOpen(true);
    getEmaiBody(organization.email, organization.id, organization.nome);
  };

  const handleClose = () => setOpen(false);

  const networkManager = new NetworkManager();

  const handleFetchOrganizations = async () => {
    try {
      const data = await networkManager.getAllReportsInLatestMonth();
      setReports(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao buscar organizações: ", error);
    }
  };

  const handleChange = (event) => {
    setIsLoading(true);
    setChecked(event.target.checked);
    localStorage.setItem("env", checked ? "stage" : "produc");

    handleFetchOrganizations();
  };

  const sendEmail = async (email, organizationId, organizationName) => {
    setsended(email);
    setIsEmailSending(true);

    try {
      const data = await networkManager.sendEmail(
        email,
        organizationId,
        localStorage.getItem("env")
      );
      console.log("Enviado... " + data);
    } catch (error) {
      console.error("Erro ao enviar e-mail: ", error);
    } finally {
      setIsEmailSending(false);
    }
  };

  const getEmaiBody = (email, organizationId, organizationName) => {
    networkManager
      .getEmailBody(email, organizationId, localStorage.getItem("env"))
      .then((data) => {
        SethtmlBodyTex(data);
      });
  };

  const HandleLogOut = async () => {
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    setChecked(localStorage.getItem("env") == "stage" ? false : true);
    handleFetchOrganizations();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredOrganizations = reports.filter((organization) =>
    organization.titulo.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderSkeleton = () => {
    return [...Array(5)].map((_, index) => (
      <div key={index} style={{ marginBottom: "10px" }}>
        <Skeleton variant="rectangular" width="100%" height={118} />
      </div>
    ));
  };

  return (
    <div style={{ flex: 1, marginTop: "70px" }}>
      <FormGroup>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Stack>
            <Typography variant="h6">Tabela de Relatorios de vendas</Typography>
            <TextField
              label="Buscar Organização"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{
                marginBottom: "50px",
                marginTop: "20px",
                height: "20px",
                width: "300px",
              }}
            />
          </Stack>
          <FormControlLabel
            value="start"
            control={
              <Stack direction="row" alignItems="center">
                <Typography>Teste</Typography>
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                  color="warning"
                />
                <Typography>Produção</Typography>
              </Stack>
            }
            label="Modo de interação"
            labelPlacement="top"
          />
        </div>
      </FormGroup>

      {isLoading ? (
        renderSkeleton()
      ) : filteredOrganizations.length > 0 ? (
        <OrganizationsTable
          reports={filteredOrganizations}
          isLoading={isLoading}
          onPreview={handleOpen}
          onSendEmail={sendEmail}
          isEmailSending={isEmailSending}
        />
      ) : (
        <Typography style={{ textAlign: "center", marginTop: "100px" }}>
          Organização não encontrada
        </Typography>
      )}

      <Dialog
        open={open}
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Preview</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {htmlBodyTex === "" ? (
              "loading"
            ) : (
              <div dangerouslySetInnerHTML={{ __html: htmlBodyTex }} />
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default OrganizationsPage;
