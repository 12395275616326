import React, { useState, useEffect } from "react";
import {
  Button,
  List,
  ListItem,
  Typography,
  useTheme,
  Card,
  CardContent,
  Tabs,
  Tab,
  Stack,
  Modal,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import DrawerAppBar from "../components/DrawerAppBar";
import { DeleteForeverOutlined, EditNote } from "@mui/icons-material";
import { toast, message, ToastContainer } from "react-toastify";
import { Divider } from "@material-ui/core";
import NetworkManager from "../Network/NetworkManager";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

const SupplierDetailsPage = ({ supplier }) => {
  const [suppliers, setSuppliers] = useState([]); // Use state to manage suppliers
  const [isDeleting, setIsDeleting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    nome: suppliers.nome,
    email: suppliers.email,
    telemovel: suppliers.telemovel,
  });

  const [isAuthorModalOpen, setIsSupplierstModalOpen] = useState(false);
  const [isDeleteSupplierModalOpen, setisDeleteSupplierModalOpen] =
    useState(false);

  const params = useParams();

  const navigate = useNavigate();

  const handleRouter = async (route) => {
    navigate(route);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  const networkManager = new NetworkManager();
  const fetchSupplier = async () => {
    try {
      const response = await networkManager.getSuppliersById(params.id);
      setSuppliers(response);

      console.log(response);
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchSupplier();
  }, [params.id]);

  const updateSupplier = async () => {
    setIsSubmitting(true);
    try {
      await networkManager.updateSuppliers(params.id, {
        nome: formData.nome,
        email: formData.email,
        telemovel: formData.telemovel,
      });

      toast.success("Fornecedor actualizado com sucesso!");
      setIsSubmitting(false);
      fetchSupplier();
    } catch (error) {
      console.error("Erro ao criar fornecedor: ", error);
      toast.error("Erro ao criar fornecedor!");
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteSupplier = async () => {
    setIsDeleting(true);
    try {
      await networkManager.deleteSuppliers(params.id);

      handleRouter("/suppliers");
      toast.success("Fornecedor eliminado");
    } catch (error) {
      toast.error("Erro ao eliminar o fornecedor " + error);
      throw new Error(error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleOpenSuppliersModal = () => setIsSupplierstModalOpen(true);
  const handleCloseSuppliersModal = () => setIsSupplierstModalOpen(false);

  const handleOpenDeleteSupplierModal = () =>
    setisDeleteSupplierModalOpen(true);

  const handleCloseDeleteSupplierModal = () =>
    setisDeleteSupplierModalOpen(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <DrawerAppBar>
      <Stack
        spacing={2}
        sx={{ marginTop: 10, padding: 2, justifyContent: "space-between" }}
        direction="row"
      >
        <Stack>
          <Typography variant="h4">{suppliers.nome}</Typography>
          <Typography>{suppliers.email}</Typography>
          <Typography>{suppliers.telemovel}</Typography>
        </Stack>

        <Stack
          direction={"row"}
          sx={{
            gap: 2,
          }}
        >
          <Button
            startIcon={<EditNote />}
            color="primary"
            variant="contained"
            size="small"
            onClick={() => handleOpenSuppliersModal()}
            style={{
              width: "100px",
              height: "40px",
            }}
          >
            Editar
          </Button>
          <Button
            startIcon={<DeleteForeverOutlined />}
            color="error"
            variant="contained"
            size="small"
            onClick={handleOpenDeleteSupplierModal}
            style={{
              width: "100px",
              height: "40px",
            }}
          >
            Apagar
          </Button>
        </Stack>
      </Stack>
      <Typography variant="h5" sx={{ marginTop: 2, padding: 2 }}>
        Livros
      </Typography>
      <Divider
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          color: "#000000",
          backgroundColor: "#000000",
          width: "97%",
          height: 2,
          marginTop: 2,
          marginBottom: 2,
        }}
      />
      <Grid container spacing={2} p="20px">
        {/* {suppliers.map((book) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={book.id}>
            <Card variant="outlined" sx={{ height: "100%" }}>
              <CardMedia
                component="img"
                height="200"
                image={book.imageUrl}
                alt={book.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {book.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {book.author}
                </Typography>
              </CardContent>

              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="start"
                padding={1}
              >
                <Button
                  endIcon={<EditNote />}
                  size="small"
                  onClick={() => handleEditBook(book)}
                >
                  Editar
                </Button>
                <Button
                  endIcon={<DeleteForeverOutlined />}
                  style={{ color: "red" }}
                  size="small"
                >
                  Remover
                </Button>
              </Stack>
            </Card>
          </Grid>
        ))} */}
      </Grid>

      <Modal
        open={isAuthorModalOpen}
        onClose={handleCloseSuppliersModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editar dados do fornecedor
          </Typography>
          <TextField
            fullWidth
            label="Nome"
            margin="normal"
            defaultValue={suppliers.nome === undefined ? "" : suppliers.nome}
            onChange={(event) => {
              setFormData({ ...formData, nome: event.target.value });
            }}
          />
          <TextField
            fullWidth
            label="Email"
            margin="normal"
            defaultValue={suppliers.email === undefined ? "" : suppliers.email}
            onChange={(event) => {
              setFormData({ ...formData, email: event.target.value });
            }}
          />
          <TextField
            fullWidth
            label="Telefone"
            margin="normal"
            defaultValue={
              suppliers.telemovel === undefined ? "" : suppliers.telemovel
            }
            onChange={(event) => {
              setFormData({ ...formData, telemovel: event.target.value });
            }}
          />

          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() => {
              updateSupplier();
              handleCloseSuppliersModal();
            }}
          >
            {isSubmitting ? <Spinner animation="grow" /> : "Criar"}
          </Button>
        </Box>
      </Modal>
      <Modal
        open={isDeleteSupplierModalOpen}
        onClose={handleCloseDeleteSupplierModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Eliminar fornecedor
          </Typography>

          <Typography id="modal-modal-title" variant="h6" component="p">
            Tem a certeza que quer eliminar{" "}
            <strong
              style={{
                color: "red",
              }}
            >
              {suppliers.nome}
            </strong>
          </Typography>

          <Button
            fullWidth
            variant="outlined"
            style={{
              color: "red",
            }}
            sx={{ mt: 2 }}
            onClick={() => {
              deleteSupplier();
              handleCloseSuppliersModal();
            }}
          >
            {isDeleting ? <Spinner animation="grow" /> : "Eliminar"}
          </Button>
        </Box>
      </Modal>
      <ToastContainer />
    </DrawerAppBar>
  );
};

export default SupplierDetailsPage;
