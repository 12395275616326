import React, { useState, useEffect } from "react";
import {
  Button,
  List,
  ListItem,
  Typography,
  useTheme,
  Card,
  CardContent,
  Tabs,
  Tab,
  Stack,
  Modal,
  Box,
  TextField,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import NetworkManager from "../Network/NetworkManager";
import { Spinner } from "react-bootstrap";
import { toast, message, ToastContainer } from "react-toastify";
import { Select } from "@material-ui/core";
import { SelectAllOutlined, SelectAllRounded } from "@mui/icons-material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export function ContractsList() {
  const [contracts, setContracts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contractId, setContractId] = useState(0);

  const [formData, setFormData] = useState({
    num: "",
    validade: "",
    fornecedorId: "",
  });

  const [isAuthorModalOpen, setIsSupplierstModalOpen] = useState(false);

  const [editContractOpenModal, setEditContractOpenModal] = useState(false);
  const [isDeleteContractModalOpen, setisDeleteContractModalOpen] =
    useState(false);

  const theme = useTheme();
  const navigate = useNavigate();

  const networkManager = new NetworkManager();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const contracts = await networkManager.getAllContracts(0, 1000);
      const suppliers = await networkManager.getAllSuppliers(0, 1000);

      setContracts(contracts);
      setSuppliers(suppliers);
    } catch (error) {
      console.error("Erro ao buscar dados de fornecedores e editores: ", error);
      fetchData();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const createContracts = async () => {
    setIsSubmitting(true);
    try {
      const contracts = await networkManager.createContracts(
        formData.num,
        formData.validade,
        formData.fornecedorId
      );
      setContracts(contracts);

      toast.success("Contrato criado com sucesso!");
      setIsSubmitting(false);
      fetchData();
    } catch (error) {
      console.error("Erro ao criar contrato: ", error);
      toast.error("Erro ao criar contrato!");
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const editContract = async (id) => {
    setIsSubmitting(true);
    try {
      const contracts = await networkManager.updateContracts(id, {
        num: formData.num,
        validade: formData.validade,
        fornecedorId: formData.fornecedorId,
      });
      setContracts(contracts);
      toast.success("Contrato editado com sucesso!");
      setIsSubmitting(false);
      fetchData();
    } catch (error) {
      console.error("Erro ao editar contrato: ", error);
      toast.error("Erro ao editar contrato!");
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteContract = async (id) => {
    setIsSubmitting(true);
    try {
      const contracts = await networkManager.deleteContracts(id);
      setContracts(contracts);
      toast.success("Contrato excluido com sucesso!");
      setIsSubmitting(false);
      fetchData();
    } catch (error) {
      console.error("Erro ao excluir contrato: ", error);
      toast.error("Erro ao excluir contrato!");
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenSuppliersModal = () => setIsSupplierstModalOpen(true);
  const handleCloseSuppliersModal = () => setIsSupplierstModalOpen(false);

  const handleOpenEditContractModal = (id) => {
    setEditContractOpenModal(true);
    setContractId(id);

    console.log(id);
  };
  const handleCloseEditContractModal = () => setEditContractOpenModal(false);

  const handleOpenDeleteContractModal = (id) => {
    setisDeleteContractModalOpen(true);
    setContractId(id);
  };
  const handleCloseDeleteContractModal = () =>
    setisDeleteContractModalOpen(false);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    gap: 6,
  };

  return (
    <Stack marginTop={theme.spacing(8)}>
      <Tabs value={tabValue} onChange={handleChangeTab} centered>
        <Tab label="Contratos" />
      </Tabs>

      {tabValue === 0 && (
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            marginTop: theme.spacing(7),
          }}
        >
          <Button onClick={handleOpenSuppliersModal}>Novo Contrato</Button>

          {contracts == undefined || isLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spinner animation="grow" />
            </div>
          ) : (
            contracts.map((contract, index) => (
              <ListItem
                key={index}
                style={{ padding: theme.spacing(1) }}
                component="div"
                disablePadding
              >
                <Card
                  variant="outlined"
                  sx={{ width: "100%" }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: theme.spacing(1),
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" component="div">
                      Numero de contrato: {contract.num}
                    </Typography>
                    <Typography variant="body2">
                      Validade: {dayjs(contract.validade).format("DD/MM/YYYY")}
                    </Typography>
                    <Typography variant="body2">
                      Id do fornecedor: {contract.fornecedorId}
                    </Typography>
                  </CardContent>

                  <Stack direction={"row"} gap={2}>
                    <Button
                      onClick={() => handleOpenEditContractModal(contract.id)}
                      variant="contained"
                      style={{
                        height: "40px",
                      }}
                    >
                      Editar
                    </Button>
                    <Button
                      onClick={() => handleOpenDeleteContractModal(contract.id)}
                      variant="text"
                      style={{
                        height: "40px",
                        color: "red",
                      }}
                    >
                      Deletar
                    </Button>
                  </Stack>
                </Card>
              </ListItem>
            ))
          )}
        </List>
      )}

      <Modal
        open={isAuthorModalOpen}
        onClose={handleCloseSuppliersModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Novo contrato
          </Typography>

          <Stack gap={3}>
            <TextField
              fullWidth
              label="Numero do contraro"
              margin="normal"
              onChange={(event) => {
                setFormData({ ...formData, num: event.target.value });
              }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Validade"
                value={dayjs(formData.validade)}
                onChange={(newValue) => {
                  setFormData({ ...formData, validade: newValue });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            <Stack>
              <InputLabel id="supplier-select-label">
                Selecione o fornecedor
              </InputLabel>
              <Select
                value={formData.fornecedorId}
                label="Selecione o fornecedor"
                title="Selecione o fornecedor"
                color="primary"
                placeholder="Selecione o fornecedor"
                IconComponent={() => <SelectAllRounded />}
                onChange={(event) =>
                  setFormData({ ...formData, fornecedorId: event.target.value })
                }
              >
                <MenuItem value="">Selecione o fornecedor</MenuItem>
                {suppliers.map((supplier) => (
                  <MenuItem value={supplier.id} key={supplier.id}>
                    {supplier.nome}
                  </MenuItem>
                ))}
              </Select>
            </Stack>

            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 2 }}
              onClick={() => {
                createContracts();
                handleCloseSuppliersModal();
              }}
            >
              {isSubmitting ? <Spinner animation="grow" /> : "Criar"}
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={editContractOpenModal}
        onClose={handleCloseEditContractModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editar contrato
          </Typography>

          <Stack gap={3}>
            <TextField
              fullWidth
              defaultValue={formData.num}
              label="Numero do contraro"
              margin="normal"
              onChange={(event) => {
                setFormData({ ...formData, num: event.target.value });
              }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Validade"
                defaultValue={dayjs(formData.validade)}
                value={dayjs(formData.validade)}
                onChange={(newValue) => {
                  setFormData({ ...formData, validade: newValue });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            <Stack>
              <InputLabel id="supplier-select-label">
                Selecione o fornecedor
              </InputLabel>
              <Select
                value={formData.fornecedorId}
                label="Selecione o fornecedor"
                title="Selecione o fornecedor"
                color="primary"
                placeholder="Selecione o fornecedor"
                IconComponent={() => <SelectAllRounded />}
                onChange={(event) =>
                  setFormData({ ...formData, fornecedorId: event.target.value })
                }
              >
                <MenuItem value="">Selecione o fornecedor</MenuItem>
                {suppliers.map((supplier) => (
                  <MenuItem value={supplier.id} key={supplier.id}>
                    {supplier.nome}
                  </MenuItem>
                ))}
              </Select>
            </Stack>

            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 2 }}
              onClick={() => {
                editContract(contractId);
                handleCloseEditContractModal();
              }}
            >
              {isSubmitting ? <Spinner animation="grow" /> : "Editar"}
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={isDeleteContractModalOpen}
        onClose={handleCloseDeleteContractModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Eliminar contrato
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2, color: "red" }}>
            Tem a certeza que pretende eliminar o contrato?
          </Typography>

          <Stack gap={3}>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 2 }}
              onClick={() => {
                deleteContract(contractId);
                handleCloseDeleteContractModal();
              }}
            >
              {isSubmitting ? <Spinner animation="grow" /> : "Eliminar"}
            </Button>
          </Stack>
        </Box>
      </Modal>

      <ToastContainer />
    </Stack>
  );
}
