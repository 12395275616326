import axios from "axios";
import Cookies from "js-cookie";

class NetworkManager {
  constructor() {
    this.API_BASE_URL = "https://api.kioxke.ao/v0.4/";
    this.API_BASE_ADMIN_URL = "https://admin.kioxke.ao/api/v0.1/";
    this.MIGRATION_API_URL =
      "https://kioxle-api.jollybush-117792be.southafricanorth.azurecontainerapps.io/";
  }

  async handleRequest(
    method,
    endpoint,
    data = {},
    config = {},
    isAdmin = false,
    env
  ) {
    const authToken = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("RefreshToken");

    const defaultHeaders = {
      Authorization: `Bearer ${authToken}`,
      RefreshToken: `Bearer ${refreshToken}`,
      SEGMENTATION: env, // Enviroment network (TEST or Production)
      "Content-Type":
        data instanceof FormData ? "multipart/form-data;" : "application/json;",
    };

    const headers = { ...defaultHeaders, ...config.headers };
    const requestData =
      method.toLowerCase() === "get" ? { params: data } : { data: data };

    try {
      const response = await axios({
        method,
        maxBodyLength: Infinity,
        url: `${
          isAdmin ? this.API_BASE_ADMIN_URL : this.API_BASE_URL
        }${endpoint}`,
        ...requestData,
        headers,
        ...config,
      });

      return response.data;
    } catch (error) {
      console.log(`Error making ${method} request to ${endpoint}:`, error);
      console.error(`Error making ${method} request to ${endpoint}:`, error);
      throw error;
    }
  }

  async insertSingleBook(data, config = {}) {
    return this.handleRequest("post", "book/upload", data, config, true);
  }

  async getRequest(params, config = {}) {
    return this.handleRequest("get", "", { params, ...config });
  }

  async getAllNumContracts(params, config = {}) {
    return this.handleRequest(
      "get",
      "book/getAllcontractsNum?db=produc",
      params,
      config,
      true
    );
  }

  async getBooksByViews() {
    const authToken = localStorage.getItem("token");

    try {
      const response = await axios.get(
        `${this.API_BASE_URL}getbooks?by=views`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            RefreshToken: `Bearer ${localStorage.getItem("RefreshToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error getting books by views:", error);
      throw error;
    }
  }

  async getEmailTemplate(index) {
    return axios.get(`${this.API_BASE_URL}emails/templates/${index}`);
  }

  async getAllEmailTemplates() {
    return this.handleRequest("get", "emails/templates/all", {}, {});
  }

  async setEmailTemplate(data) {
    return this.handleRequest("post", "emails/template/set", data, {});
  }

  async deleteBook(id) {
    return this.handleRequest("delete", `book/delete/${id}`);
  }

  async getPurchase() {
    return this.handleRequest("get", "book/getPurchase");
  }

  async setPurchase(data, config = {}) {
    return this.handleRequest("post", "register/setPurchase", data, config);
  }

  async fetchOrganizations(environment) {
    try {
      return this.handleRequest(
        "get",
        `organizations?db=${environment}`,
        {},
        {},
        true
      );
    } catch (error) {
      console.error("Error fetching organizations:", error);
      throw error;
    }
  }

  async sendEmail(email, organizationId, environment) {
    return this.handleRequest(
      "get",
      `salesReportEmail?p=${email}&organizationId=${organizationId}&db=${environment}`,
      {},
      {},
      true
    );
  }

  async getEmailBody(email, organizationId, environment) {
    return this.handleRequest(
      "post",
      `email/preview/${organizationId}`,
      {},
      {},
      true
    );
  }

  async fetchSalesReport(id, environment, data = {}) {
    try {
      return this.handleRequest(
        "post",
        `salesReport?id=${id}&db=${environment}`,
        data,
        {},
        true
      );
    } catch (error) {
      console.error("Error fetching sales report:", error);
      throw error;
    }
  }

  async fetchSalesFiltered(id, environment, data = {}) {
    try {
      return this.handleRequest(
        "post",
        `salesReport?id=${id}&db=${environment}`,
        data,
        {},
        true
      );
    } catch (error) {
      console.error("Error fetching sales report:", error);
      throw error;
    }
  }

  async fetchAllUsers() {
    try {
      return this.handleRequest("get", `users/getall`, {}, {}, true);
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  }

  async postLogin(email, password) {
    try {
      const { data, status } = await axios.post(`${this.API_BASE_URL}login`, {
        use_email: email,
        use_senha: password,
      });
      if (status !== 200) {
        throw new Error("Error on login");
      }
      localStorage.setItem("token", data.token);
      localStorage.setItem("RefreshToken", data.refreshToken);

      Cookies.set("AUTH", password, { expires: 2 });

      return data;
    } catch (error) {
      console.error("Error on login:", error);
      throw error;
    }
  }

  async fetchOrganizationSales(environment) {
    try {
      return this.handleRequest(
        "get",
        `users/organizations?db=${environment}`,
        true
      );
    } catch (error) {
      console.error("Error fetching organization sales:", error);
      throw error;
    }
  }

  async addDiscount(data, environment) {
    try {
      return this.handleRequest(
        "post",
        `book/set?db=${environment}`,
        data,
        {},
        true
      );
    } catch (error) {
      console.error("Error adding discount:", error);
      throw error;
    }
  }

  async addBookToUser(data, environment) {
    try {
      return this.handleRequest(
        "post",
        "book/set?db=" + environment,
        data,
        {},
        true
      );
    } catch (error) {
      console.error("Error adding book to user:", error);
      throw error;
    }
  }

  async editBook(data, environment) {
    try {
      return this.handleRequest(
        "post",
        `book/edit?db=${environment}`,
        data,
        {},
        true
      );
    } catch (error) {
      console.error("Error editing book:", error);
      throw error;
    }
  }

  async InsertBookForm(data) {
    try {
      const res = await axios.post(`${this.MIGRATION_API_URL}livros/`, data);
      return res.data;
    } catch (error) {
      console.error("Error adding book to user:", error);
      throw error;
    }
  }

  async activateBook(id) {
    try {
      return this.handleRequest(
        "post",
        "book/split/update/" + id,
        {},
        {},
        true
      );
    } catch (error) {
      console.error("Error adding book to user:", error);
      throw error;
    }
  }

  async getAllContracts(skip, limit) {
    try {
      const res = await axios.get(
        `${this.MIGRATION_API_URL}contratos/?skip=${skip}&limit=${limit}`
      );

      return res.data;
    } catch (error) {
      console.error("Error getting contracts:", error);
      throw error;
    }
  }

  async getContractsById(id) {
    try {
      const res = await axios.get(`${this.MIGRATION_API_URL}contratos/${id}`);
      console.log(res);
      return res.data;
    } catch (error) {
      console.error("Error getting contracts:", error);
      throw error;
    }
  }
  async updateContracts(id, { num, validade, fornecedorId }) {
    try {
      const res = await axios.put(`${this.MIGRATION_API_URL}contratos/${id}`, {
        id,
        num,
        validade,
        fornecedorId,
      });
      return res.data;
    } catch (error) {
      console.error("Error getting contracts:", error);
      throw error;
    }
  }

  async deleteContracts(id) {
    try {
      return await axios.delete(`${this.MIGRATION_API_URL}contratos/${id}`);
    } catch (error) {
      console.error("Error on deleting contracts");
      console.log(error);
      throw error;
    }
  }

  async createContracts(num, validade, fornecedorId) {
    try {
      const randomId = Math.floor(Math.random() * 1000000);
      const res = await axios.post(`${this.MIGRATION_API_URL}contratos/`, {
        id: randomId,
        num,
        validade,
        fornecedorId,
      });

      return res.data;
    } catch (error) {
      console.error("Error creating contracts:", error);
      throw error;
    }
  }

  async getAllSuppliers(skip, limit) {
    const cacheKey = `suppliers_${skip}_${limit}`;
    const cachedData = localStorage.getItem(cacheKey);

    if (cachedData) {
      console.log("Returning cached data");
      return JSON.parse(cachedData);
    }

    try {
      const res = await axios.get(
        `${this.MIGRATION_API_URL}fornecedores/?skip=${skip}&limit=${limit}`
      );
      console.log(res);

      // Armazenar os dados no localStorage
      localStorage.setItem(cacheKey, JSON.stringify(res.data));

      // Depois de 2 dias eliminar o cache
      setTimeout(() => {
        localStorage.removeItem(cacheKey);
      }, 172800000);

      return res.data;
    } catch (error) {
      console.error("Error on getting all suppliers");
      console.log(error);
      throw error;
    }
  }

  async getSuppliersById(id) {
    try {
      const res = await axios.get(
        `${this.MIGRATION_API_URL}fornecedores/${id}`
      );
      return res.data;
    } catch (error) {
      console.error("Error on getting all suppliers");
      console.log(error);
      throw error;
    }
  }

  async createSuppliers(nome, email, telemovel) {
    try {
      const res = await axios.post(`${this.MIGRATION_API_URL}fornecedores/`, {
        nome,
        email,
        telemovel,
      });

      return res.data;
    } catch (error) {
      console.error("Error createing contracts:", error);
      throw error;
    }
  }

  async updateSuppliers(id, { nome, email, telemovel }) {
    try {
      const res = await axios.put(
        `${this.MIGRATION_API_URL}fornecedores/${id}`,
        {
          nome,
          email,
          telemovel,
        }
      );

      console.log(res);

      return res.data;
    } catch (error) {
      console.error("Error update contracts:", error);
      throw error;
    }
  }

  async deleteSuppliers(id) {
    try {
      return await axios.delete(`${this.MIGRATION_API_URL}fornecedores/${id}`);
    } catch (error) {
      console.error("Error on deleting suppliers");
      console.log(error);
      throw error;
    }
  }

  async getAllReportsInLatestMonth() {
    try {
      const res = await axios.get(`${this.MIGRATION_API_URL}users/report`);
      return res.data;
    } catch (error) {
      console.error("Error on getting all reports in latest month");
      console.log(error);
      throw error;
    }
  }

  async getReportBySupplierId(id) {
    try {
      const res = await axios.get(
        `${this.MIGRATION_API_URL}users/report/${id}`
      );
      return res.data;
    } catch (error) {
      console.error("Error on getting all reports in latest month");
      console.log(error);
      throw error;
    }
  }

  async getAllBooks(skip, limit) {
    try {
      const res = await axios.get(
        `${this.MIGRATION_API_URL}livros/?skip=${skip}&limit=${limit}`
      );
      return res.data;
    } catch (error) {
      console.error("Error on getting all books");
      console.log(error);
      throw error;
    }
  }

  async getBookById(id) {
    try {
      const res = await axios.get(`${this.MIGRATION_API_URL}livros/${id}`);
      return res.data;
    } catch (error) {
      console.error("Error on getting all books");
      console.log(error);
      throw error;
    }
  }
}
export default NetworkManager;
