import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  TextField,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Stack,
  Card,
  CardContent,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridOnIcon from "@mui/icons-material/GridOn";
import NetworkManager from "../Network/NetworkManager";

const SingleOrg = () => {
  const [salesData, setSalesData] = useState([]);
  const [allSalesData, setAllSalesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewType, setViewType] = useState("table");
  const [startDate, setStartDate] = useState(dayjs().startOf("year"));
  const [endDate, setEndDate] = useState(dayjs());
  const [searchTerm, setSearchTerm] = useState("");

  const { id } = useParams();

  const formatDate = (dateString) => {
    return dayjs(dateString).format("DD/MM/YYYY");
  };

  const networkManager = new NetworkManager();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const fetchedData = await networkManager.getReportBySupplierId(id);

      console.log(fetchedData);
      setAllSalesData(fetchedData);
      setSalesData(fetchedData);
    } catch (error) {
      console.error("Erro ao buscar dados de vendas: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const applyFilter = () => {
    const filteredData = allSalesData.filter((sale) => {
      const saleDate = dayjs(sale.databuy);
      const matchesDate =
        saleDate.isAfter(startDate) && saleDate.isBefore(endDate);
      const matchesSearch =
        sale.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sale.referencia.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesDate && matchesSearch;
    });
    setSalesData(filteredData);
  };

  const resetFilter = () => {
    setSalesData(allSalesData);
    setStartDate(dayjs().startOf("year"));
    setEndDate(dayjs());
    setSearchTerm("");
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const SalesCard = ({
    titulo,
    referencia,
    forma_pagamento,
    databuy,
    TotalPrice,
  }) => (
    <Card sx={{ minWidth: 275, m: 1 }}>
      <CardContent>
        <Typography variant="h6">{titulo}</Typography>
        <Typography>Referência: {referencia}</Typography>
        <Typography>Forma de Pagamento: {forma_pagamento}</Typography>
        <Typography>Data: {formatDate(databuy)}</Typography>
        <Typography>Preço: {TotalPrice.toFixed(2)} Kz</Typography>
      </CardContent>
    </Card>
  );

  return (
    <Stack spacing={3} sx={{ p: 6, marginTop: 6 }}>
      <Typography variant="h4">{salesData[0]?.Fornecedor}</Typography>

      <Stack direction="row" spacing={2} alignItems="center">
        <TextField
          label="Buscar por título ou referência"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Data Inicial"
            value={startDate}
            onChange={(newDate) => setStartDate(newDate)}
          />
          <DatePicker
            label="Data Final"
            value={endDate}
            onChange={(newDate) => setEndDate(newDate)}
          />
        </LocalizationProvider>
        <Button variant="contained" onClick={applyFilter}>
          Aplicar Filtros
        </Button>
        <Button variant="outlined" onClick={resetFilter}>
          Resetar Filtros
        </Button>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Button
          onClick={() => setViewType("card")}
          variant={viewType === "card" ? "contained" : "outlined"}
        >
          <GridOnIcon />
        </Button>
        <Button
          onClick={() => setViewType("table")}
          variant={viewType === "table" ? "contained" : "outlined"}
        >
          <FormatListBulletedIcon />
        </Button>
      </Stack>

      {isLoading ? (
        <Typography>Carregando...</Typography>
      ) : viewType === "card" ? (
        <Stack direction="row" flexWrap="wrap" justifyContent="center">
          {salesData.map((sale) => (
            <SalesCard key={sale.id} {...sale} />
          ))}
        </Stack>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Título</TableCell>
              <TableCell>Referência</TableCell>
              <TableCell>Forma de pagamento</TableCell>
              <TableCell>Data de Compra</TableCell>
              <TableCell>Preço total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salesData.map((sale) => (
              <TableRow key={sale.id}>
                <TableCell>{sale.titulo}</TableCell>
                <TableCell>{sale.referencia}</TableCell>
                <TableCell>{sale.forma_pagamento}</TableCell>
                <TableCell>{formatDate(sale.databuy)}</TableCell>
                <TableCell>{sale.TotalPrice.toFixed(2)} Kz</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Stack>
  );
};

export default SingleOrg;
