import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  TablePagination,
} from "@material-ui/core";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";

function OrganizationsTable({
  reports,
  isLoading,
  onPreview,
  onSendEmail,
  isEmailSending,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const navigate = useNavigate();

  // Função para agrupar os relatórios por fornecedor
  const groupByFornecedor = (reports) => {
    return reports.reduce((acc, report) => {
      const fornecedor = report.Fornecedor;
      if (!acc[fornecedor]) {
        acc[fornecedor] = [];
      }
      acc[fornecedor].push(report);
      return acc;
    }, {});
  };

  const groupedReports = groupByFornecedor(reports);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <React.Fragment>
      <TableContainer
        component={Paper}
        style={{ marginTop: "20px", boxShadow: "0px 4px 12px rgba(0,0,0,0.1)" }}
      >
        <Table aria-label="collapsible table">
          <TableBody>
            {isLoading
              ? [...Array(rowsPerPage)].map((_, index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={6}>
                      <Skeleton variant="text" />
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton
                        variant="rectangular"
                        width={210}
                        height={118}
                      />
                    </TableCell>
                  </TableRow>
                ))
              : Object.keys(groupedReports)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((fornecedor) => {
                    const reports = groupedReports[fornecedor];
                    return (
                      <TableRow
                        key={fornecedor}
                        style={{
                          backgroundColor: "#f0f0f0",
                          cursor: "pointer",
                        }}
                      >
                        <TableCell>
                          <Typography variant="h6">{fornecedor}</Typography>
                          <Typography variant="subtitle2">
                            {reports.length} relatório(s)
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Ver detalhes do relatório">
                            <IconButton
                              color="default"
                              onClick={() =>
                                navigate(
                                  `/organization/${reports[0].FornecedorId}`
                                )
                              }
                            >
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={Object.keys(groupedReports).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
}

export { OrganizationsTable };
